import React, { useContext, useState, useEffect, createContext } from "react";
import FullPageSpinner from "../Components/FullPageSpinner";
import { auth as firebaseAuth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

import {
  onAuthStateChanged,
  signOut as firebaseSignOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useToast } from "@chakra-ui/react";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const errorMessages = {
    "auth/invalid-phone-number": "The phone number entered is invalid",
    "auth/quota-exceeded":
      "Phone verification is facing problems. Please try again later or use other methods of login",
    "auth/user-disabled": "The user has been disabled",
    "auth/captcha-check-failed": "Please refresh the browser and try again",
    "auth/invalid-email": "The email address entered is invalid",
    "auth/expired-action-code": "The passwordless link has expired",
    "auth/popup-closed-by-user": "The popup has been closed",
    "auth/too-many-requests": "Too many requests. Try again later",
    default: "Some error has occured",
  };

  const getAuthErrorMessage = code =>
    errorMessages[code] ? errorMessages[code] : errorMessages.default;

  const signInWithPopupGoogle = () => {
    const google = {};
    google.provider = new GoogleAuthProvider();
    return signInWithPopup(firebaseAuth, google.provider).then(result => {
      google.credential = GoogleAuthProvider.credentialFromResult(result);
      google.token = google.credential.accessToken;
      return result;
    });
  };

  const signOut = () => firebaseSignOut(firebaseAuth).then(() => removeToken());

  const getUser = async currentUser => {
    if (currentUser) {
      setIsLoading(true);
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) setUser({ ...currentUser, data: userDoc.data() });
      else setUser({ ...currentUser, data: null });
    } else setUser(null);
    setIsLoading(false);
  };

  const getToken = password => {
    return new Promise((res, rej) => {
      if (password && password === process.env.REACT_APP_SESSION_TOKEN) {
        setSessionToken(password);
        res();
      } else rej("Wrong Password");
    });
  };
  const verifyToken = () => {
    if (sessionToken && sessionToken !== process.env.REACT_APP_SESSION_TOKEN)
      removeToken();
  };
  const removeToken = () => {
    setSessionToken(null);
  };

  useEffect(() => {
    //initialize firebase auth listener
    const unsubscribe = onAuthStateChanged(firebaseAuth, async currentUser => {
      let isAuthorized = false;
      if (currentUser) {
        const token = await currentUser.getIdTokenResult();
        isAuthorized = !!token.claims.admin;
      }
      console.log("AUTHORIZED >> ", isAuthorized);
      if (!isAuthorized && currentUser) {
        signOut();
        toast({
          status: "error",
          description: "You are not authorized to be here",
        });
      }
      getUser(isAuthorized ? currentUser : null);
    });

    //unsubscribe auth listener on unmount
    return unsubscribe;
  }, []);

  useEffect(() => {
    verifyToken();
  }, [sessionToken]);

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <AuthContext.Provider
      value={{
        user,
        sessionToken,
        getToken,
        signOut,
        signInWithPopupGoogle,
        getAuthErrorMessage,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthProvider, useAuth };
