import React from "react";
import "focus-visible/dist/focus-visible";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import { useAuth } from "./Contexts/AuthContext";
import FullPageSpinner from "./Components/FullPageSpinner";
import { BrowserRouter as Router } from "react-router-dom";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

function App() {
  const { user } = useAuth();
  const sessionToken = true;
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Router>
        {user && sessionToken ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Router>
    </React.Suspense>
  );
}

export default App;
